import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';

const NotFoundPage = () => {
  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Infocus | Homepage</title>
      <meta name="keywords" content="Infocus, Data-Mining, información, análisis de datos" />
      <meta name="description" content="La información hace la diferencia"/>
      <meta property="og:title" content="Infocus | Homepage"></meta>
      <meta property="og:description" content="Infocus, Data-Mining, información, análisis de datos"></meta>
    </Helmet>

    <div style={{ marginTop: 100, textAlign: 'center' }}>
      <h1>404</h1>
      <p>Page not found</p>

      <Link to="/">Go home</Link>
    </div>
  </>)
}

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
